// @flow
import { createAction } from 'redux-actions';

import { query } from '../helpers/axios';

const API_CLIENTS = '/v1/config/screens';

type DispatchType = ({}) => Promise<any>;

export const fetchedResourcePermissions = createAction(
  'FETCHED_RESOUCE_PERMISSIONS'
);
export const fetchResourcePermissions = (params: Array<string> = []) => (
  dispatch: DispatchType
) => {
  return query(`${API_CLIENTS}?slugs=${params.join(',')}`).then(res =>
    dispatch(fetchedResourcePermissions(res.data))
  );
};
