import { createAction } from 'redux-actions';
import { query } from '../helpers/axios';

const API_SOURCES = '/v1/sources';

export const fetchedSources = createAction('FETCHED_SOURCES');

export const fetchSources = () => dispatch => {
  return query(API_SOURCES).then(res => dispatch(fetchedSources(res.data)));
};
