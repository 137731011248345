// @flow
export const checkingArchive = (
  request: () => Promise<{}>,
  obj: { getStatus: string } // getter
): Promise<any> =>
  new Promise((resolve, reject) => {
    const fetchArchiveStatus = () =>
      request().then((res: any) => {
        if (
          obj.getStatus !== 'reject' && // control by Component
          (res.data.status === 'new' || res.data.status === 'running') // status from server
        ) {
          return setTimeout(() => fetchArchiveStatus(), 3000);
        }

        if (res.data.status === 'failed' || obj.getStatus === 'reject') {
          return reject(res.data);
        }

        return resolve(res.data);
      });

    fetchArchiveStatus();
  });
