import { handleActions } from 'redux-actions';

import { parseErrors } from '../helpers/getFormat';
import {
  fetchingDeal,
  fetchedDeal,
  addCommentSuccess,
  fetchingAllComments,
  fecthedAllComments,
  fetchDealFailure,
  updateStatusDealSuccess,
  updateDealSuccess,
  updateDealFailure,
  fetchingHistory,
  fetchedHistory,
  setDataForCreateDeal,
} from '../actions/dealEdit';

const initialState = {
  allComments: { items: [] },
  data: { productType: 'mortgage', bankOffers: [] },
  errors: null,
};

export default handleActions(
  {
    [fetchingDeal](state, action) {
      return {
        // clear the previous data
        ...initialState,
        loading: true,
        allComments: state.allComments,
      };
    },

    [fetchDealFailure](
      state,
      {
        payload: { errors, code },
      }
    ) {
      return {
        ...state,
        errors: parseErrors(errors),
        loading: false,
        code,
      };
    },

    [fetchedDeal](
      state,
      {
        payload: { status: dealStatus, callBackTime, ...data },
      }
    ) {
      return {
        ...state,
        data,
        dealStatus,
        callBackTime,
        loading: false,
      };
    },

    [updateDealSuccess](
      state,
      {
        payload: { status: dealStatus, ...data },
      }
    ) {
      return {
        ...state,
        data,
        dealStatus,
      };
    },

    [updateDealFailure](state, action) {
      return { ...state, errors: parseErrors(action.payload.errors) };
    },

    [fetchingAllComments](state, action) {
      return {
        ...state,
        allComments: {
          ...state.allComments,
          loading: true,
        },
      };
    },

    [fecthedAllComments](state, action) {
      return {
        ...state,
        allComments: action.payload,
      };
    },

    [updateStatusDealSuccess](
      state,
      {
        payload: { status: dealStatus },
      }
    ) {
      return {
        ...state,
        dealStatus,
      };
    },

    [addCommentSuccess](state, action) {
      const { allComments, ...restState } = state;

      return {
        ...restState,
        allComments: {
          items: [
            {
              type: 'deal',
              entityId: action.payload.entityId,
              ...action.payload.data,
            },
            ...allComments.items,
          ],
          pager: {
            ...allComments.pager,
            total: allComments.pager.total + 1,
          },
        },
      };
    },

    [fetchingHistory](state, action) {
      return {
        ...state,
        history: {
          ...state.history,
          loading: true,
        },
      };
    },

    [fetchedHistory](state, action) {
      return {
        ...state,
        history: action.payload,
      };
    },

    [setDataForCreateDeal](state, action) {
      return {
        ...state,
        data: { ...initialState.data, ...action.payload },
      };
    },
  },
  initialState
);
