import { handleActions } from 'redux-actions';

import { fetchedClients, setFilters } from '../actions/clients';

export default handleActions(
  {
    [fetchedClients](state, action) {
      return {
        ...state,
        data: action.payload,
      };
    },

    [setFilters](state, action) {
      return {
        ...state,
        filters: action.payload,
      };
    },
  },
  { data: { pager: {} }, filters: {} }
);
