import { createAction } from 'redux-actions';

import { post, refreshAxiosInstance } from '../helpers/axios';

export const loginSuccess = createAction('LOGIN_SUCCESS');
export const loginFailure = createAction('LOGIN_FAILURE');
export const saveNewTokens = createAction('SAVE_NEW_TOKENS');
export const getRefreshAccessTokenFailure = createAction(
  'REFRESH_ACCESS_TOKEN_FAILURE'
);
export const logout = createAction('LOGOUT');
export const saveIsAuth = createAction('SAVE_IS_AUTH');

const API_LOGIN = '/login';
const API_REFRESH_TOKEN = '/token/refresh';

export const loginRequest = ({ userName, password }) => dispatch => {
  return post(API_LOGIN, { _username: userName, _password: password })
    .then(res => dispatch(loginSuccess(res.data)))
    .catch(error => {
      dispatch(loginFailure(error.response.data));
      return Promise.reject(error);
    });
};

export const getRefreshAccessToken = refresh_token => dispatch => {
  // use refresh axios instance, because this solve https://github.com/axios/axios/issues/1266#issuecomment-355368279
  // don't work for multiple request, when axios instance in eject state
  return refreshAxiosInstance
    .post(API_REFRESH_TOKEN, { refresh_token })
    .then(response => {
      dispatch(saveNewTokens(response.data));
      return response;
    })
    .catch(error => {
      dispatch(getRefreshAccessTokenFailure(error.response.data));
      return Promise.reject(error);
    });
};
