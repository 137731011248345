import AsyncComponent from './AsyncComponent';

export const Login = AsyncComponent({
  loader: () => import('../containers/Login'),
});
export const Leads = AsyncComponent({
  loader: () => import('../containers/Leads'),
});
export const LeadsImport = AsyncComponent({
  loader: () => import('../containers/LeadsImport'),
});
export const LeadCianImport = AsyncComponent({
  loader: () => import('../containers/LeadCianImport'),
});
export const LeadCreate = AsyncComponent({
  loader: () => import('../containers/LeadEdit/LeadCreate'),
});
export const LeadsProcessing = AsyncComponent({
  loader: () => import('../containers/LeadsProcessing'),
});
export const Users = AsyncComponent({
  loader: () => import('../containers/Users'),
});
export const UserEdit = AsyncComponent({
  loader: () => import('../containers/UserEdit'),
});
export const Companies = AsyncComponent({
  loader: () => import('../containers/Companies'),
});
export const CompanyEdit = AsyncComponent({
  loader: () => import('../containers/CompanyEdit'),
});
export const Profile = AsyncComponent({
  loader: () => import('../containers/Profile'),
});
export const Deals = AsyncComponent({
  loader: () => import('../containers/Deals'),
});
export const LeadEdit = AsyncComponent({
  loader: () => import('../containers/LeadEdit'),
});
export const DealEdit = AsyncComponent({
  loader: () => import('../containers/DealEdit'),
});
export const DealCreate = AsyncComponent({
  loader: () => import('../containers/DealEdit/DealCreate'),
});
export const Clients = AsyncComponent({
  loader: () => import('../containers/Clients'),
});
export const ClientEdit = AsyncComponent({
  loader: () => import('../containers/ClientEdit'),
});
export const ServiceRefresh = AsyncComponent({
  loader: () => import('../containers/ServiceRefresh'),
});
export const Calculator = AsyncComponent({
  loader: () => import('../containers/Calculator'),
});
