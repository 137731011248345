// @flow
import { createAction } from 'redux-actions';
import qs from 'qs';

import { query, post, HOST_API } from '../helpers/axios';
import { checkingArchive } from '../helpers/downloadArchive';

type DispatchType = ({}) => Promise<{}>;

const API_LEADS = '/v1/leads';
const API_FILES = '/v1/files';
const apiArchiveCheck = taskId => `${API_FILES}/archive/${taskId}`;

export const fetchedLeads = createAction('FETCHED_LEADS');
export const setFilters = createAction('SET_FILTERS');
export const updateStatusLeadsSuccess = createAction(
  'UPDATE_STATUS_LEADS_SUCCESS'
);
export const fetchingSimilarLeads = createAction('FETCHING_SIMILAR_LEADS');
export const fetchedSimilarLeadsSuccess = createAction(
  'FETCHED_SIMILAR_LEADS_SUCCESS'
);
export const fetchedSimilarLeadsFailure = createAction(
  'FETCHED_SIMILAR_LEADS_FAILURE'
);

export const fetchLeads = (params: {}) => (dispatch: any) => {
  return query(
    `${API_LEADS}${qs.stringify(params, { addQueryPrefix: true })}`
  ).then(res => dispatch(fetchedLeads(res.data)));
};

export const toggleArchiveSuccess = createAction(
  'TOGGLE_LEADS_ARCHIVE_SUCCESS'
);

export const fetchSimilarLeads = (leadId: string | number, params: {}) => (
  dispatch: any
) => {
  dispatch(fetchingSimilarLeads());
  return query(
    `${API_LEADS}/${leadId}/similar${qs.stringify(params, {
      addQueryPrefix: true,
    })}`
  )
    .then(res => dispatch(fetchedSimilarLeadsSuccess(res.data)))
    .catch(error => {
      dispatch(fetchedSimilarLeadsFailure());
      return Promise.reject();
    });
};

export const fetchExport = (data: {}, objWithStatus: { getStatus: string }) => (
  dispatch: DispatchType
) => {
  return post(
    `${API_LEADS}/export${qs.stringify(data, {
      addQueryPrefix: true,
    })}`,
    {}
  ).then(res =>
    checkingArchive(
      () => query(apiArchiveCheck(res.data.id)),
      objWithStatus
    ).then(({ result: { downloadUrl } }) => `${HOST_API}${downloadUrl}`)
  );
};
