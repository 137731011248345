import { handleActions } from 'redux-actions';
import {
  fetchingLead,
  fetchedLead,
  updateLeadSuccess,
  fetchingComments,
  fetchedComments,
  addCommentSuccess,
  updateStatusLeadSuccess,
  fetchedHistory,
  fetchingHistory,
  requestFailure,
} from '../actions/leadEdit';
import { parseErrors } from '../helpers/getFormat';

const initialState = {
  comments: { items: [] },
  data: { client: {} },
  metaData: {},
};

const parseData = ({
  data: { status: leadStatus, callBackTime, ...data } = {},
  metaData = {},
  ...restData
}) => ({
  data,
  leadStatus,
  callBackTime,
  metaData,
  ...restData,
});

const parseErrorsFormat = errors => {
  const { address, ...restErrors } = errors;

  if (address) {
    restErrors.address = address.inline;
  }

  return restErrors;
};

export default handleActions(
  {
    [fetchingLead](state, action) {
      return {
        // clear the previous data
        ...initialState,
        loading: true,
      };
    },

    [fetchedLead](state, { payload }) {
      return {
        ...state,
        ...parseData(payload),
        loading: false,
      };
    },

    [requestFailure](
      state,
      {
        payload: { errors, code },
      }
    ) {
      return {
        ...state,
        errors: parseErrorsFormat(parseErrors(errors)),
        loading: false,
        code,
      };
    },

    [updateLeadSuccess](state, { payload }) {
      return {
        ...state,
        ...parseData(payload),
      };
    },

    [updateStatusLeadSuccess](
      state,
      {
        payload: {
          data: { status: leadStatus, callBackTime },
        },
      }
    ) {
      return {
        ...state,
        leadStatus,
        callBackTime,
      };
    },

    [fetchingComments](state, action) {
      return {
        ...state,
        comments: {
          ...state.comments,
          loading: true,
        },
      };
    },

    [fetchedComments](state, action) {
      return {
        ...state,
        comments: action.payload,
      };
    },

    [addCommentSuccess](state, action) {
      return {
        ...state,
        comments: {
          items: [action.payload, ...state.comments.items],
          pager: {
            ...state.comments.pager,
            total: state.comments.pager.total + 1,
          },
        },
      };
    },

    [fetchingHistory](state, action) {
      return {
        ...state,
        history: {
          ...state.history,
          loading: true,
        },
      };
    },

    [fetchedHistory](state, action) {
      return {
        ...state,
        history: action.payload,
      };
    },
  },
  initialState
);
