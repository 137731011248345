import { saveSubscription } from '../../actions/pushManager';

const applicationServerPublicKey = process.env.REACT_APP_PUSH_PUBLIC_KEY;

const urlB64ToUint8Array = (base64String = '') => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

export default () => {
  navigator.serviceWorker.ready.then(swReg => {
    Notification.requestPermission().then(result => {
      if (result === 'granted') {
        swReg.pushManager.getSubscription().then(subscription => {
          const isSubscribed = subscription !== null;

          if (!isSubscribed) {
            subscribeUser(swReg);
          } else {
            console.log('User is subscribed');
            // send to the server just in case
            saveSubscription(subscription);
          }
        });
      }
    });
  });

  // unsubscribe
  return () => {
    navigator.serviceWorker.ready.then(swReg => {
      swReg.pushManager.getSubscription().then(subscription =>
        subscription
          .unsubscribe()
          .then(() => console.log('Successfully unsubscribed'))
          .catch(e => console.log(e))
      );
    });
  };
};

const subscribeUser = swRegistration => {
  const applicationServerKey = urlB64ToUint8Array(applicationServerPublicKey);

  swRegistration.pushManager
    .subscribe({
      userVisibleOnly: true,
      applicationServerKey,
    })
    .then(subscription => {
      console.log('pushSubscription', subscription);
      // send subscription to application server
      saveSubscription(subscription);
    })
    .catch(err => {
      console.log('Failed to subscribe the user: ', err);
    });
};
