// @flow
import { createAction } from 'redux-actions';
import qs from 'qs';

import { query, put, post } from '../helpers/axios';

type dispatchType = (arg: {}) => {};
type leadIdType = string | number;
export type ClientModel = {
  phoneNumber: string,
  lastName: string,
  firstName: string,
  patronymic: string,
  email: string,
};
// TODO: describe lead model
export type LeadModel = {
  id: number,
  phoneNumber: string,
  lastName: string,
  firstName: string,
  patronymic: string,
  email: string,
  sourceLabels: Array<string>,
  company: { id: number, name: string },
  operator: {
    lastName: string,
    firstName: string,
    patronymic: string,
  },
  comment: string,
  owner: {
    lastName: string,
    firstName: string,
    patronymic: string,
  },
  files: Array<{}>,
  client?: ClientModel,
  callBackTime: string,
  status?: string,
  productType?: string,
  externalId?: string,
};

export const API_LEAD = '/v1/leads';
const apiComments = leadId => `${API_LEAD}/${leadId}/comments`;
const apiHistory = leadId => `${API_LEAD}/${leadId}/history`;
const apiProcessing = `${API_LEAD}/processing/next`;
const apiAsDuplicate = leadId =>
  `${API_LEAD}/processing/${leadId}/as-duplicate`;

export const fetchingLead = createAction('FETCHING_LEAD');
// export const fetchLeadFailure = createAction('FETCH_LEAD_FAILURE');
export const fetchedLead = createAction('FETCHED_LEAD');

export const fetchingLeadWa = createAction('FETCHING_LEAD_WA');
// export const fetchLeadFailure = createAction('FETCH_LEAD_FAILURE');
export const fetchedLeadWa = createAction('FETCHED_LEAD_WA');

export const fetchingWA = createAction('FETCHING_WA');
// export const fetchLeadFailure = createAction('FETCH_LEAD_FAILURE');
export const fetchedWA = createAction('FETCHED_WA');

export const updateLeadSuccess = createAction('UPDATE_LEAD_SUCCESS');
export const requestFailure = createAction('REQUEST_LEAD_FAILURE');
export const fetchingComments = createAction('FETCHING_COMMENTS');
export const fetchedComments = createAction('FETCHED_COMMENTS');
export const addCommentSuccess = createAction('ADD_COMMENT_LEAD_SUCCESS');
export const updateStatusLeadSuccess = createAction(
  'UPDATE_STATUS_LEAD_SUCCESS'
);
export const fetchingHistory = createAction('FETCHING_LEAD_HISTORY');
export const fetchedHistory = createAction('FETCHED_LEAD_HISTORY');

export const fetchLead = (leadId: leadIdType) => (dispatch: dispatchType) => {
  dispatch(fetchingLead());

  return (
    query(`${API_LEAD}/${leadId}`)
      // TODO: need new format { data: {}, metaData: {} }
      .then(res => dispatch(fetchedLead({ data: res.data })))
      .catch(({ response }) => dispatch(requestFailure(response.data)))
  );
};
export const fetchLeadWaMsg = (leadId: leadIdType) => (
  dispatch: dispatchType
) => {
  dispatch(fetchingLeadWa());

  return (
    query(`${API_LEAD}/${leadId}`)
      // TODO: need new format { data: {}, metaData: {} }
      .then(res => dispatch(fetchedLeadWa({ data: res.data })))
      .catch(({ response }) => dispatch(requestFailure(response.data)))
  );
};
export const fetchLeadWa = (leadId: leadIdType) => (dispatch: dispatchType) => {
  dispatch(fetchingWA());

  return post(`${API_LEAD}/${leadId}/wa`, { template: 'request_1' })
    .then(res => dispatch(fetchedWA({ data: res.data })))
    .catch(({ response }) => dispatch(requestFailure(response.data)));
};

export const updateLead = (leadId: leadIdType, data: LeadModel) => (
  dispatch: dispatchType
) => {
  return (
    put(`${API_LEAD}/${leadId}`, data)
      // TODO: need new format { data: {}, metaData: {} }
      .then(res => dispatch(updateLeadSuccess({ data: res.data })))
      .catch(error => {
        dispatch(requestFailure(error.response.data));
        return Promise.reject();
      })
  );
};

export const createLead = (data: LeadModel) => (dispatch: dispatchType) => {
  return (
    post(API_LEAD, data)
      // TODO: need new format { data: {}, metaData: {} }
      .then(res => dispatch(updateLeadSuccess({ data: res.data })))
      .catch(error => {
        dispatch(requestFailure(error.response.data));
        return Promise.reject();
      })
  );
};

export const updateStatusLead = (
  leadId: leadIdType,
  data: LeadModel,
  actionSuccess: dispatchType
) => (dispatch: dispatchType) => {
  return put(`${API_LEAD}/${leadId}/status`, data).then(res =>
    dispatch(actionSuccess(res.data))
  );
};

export const leadToDeal = (leadId: leadIdType) => (dispatch: dispatchType) => {
  return post(`${API_LEAD}/${leadId}/to-deal`);
};

export const fetchComments = (leadId: leadIdType, params?: {}) => (
  dispatch: dispatchType
) => {
  dispatch(fetchingComments());
  return query(
    `${apiComments(leadId)}${qs.stringify(params, { addQueryPrefix: true })}`
  ).then(res => dispatch(fetchedComments(res.data)));
};

export const addComment = (
  leadId: leadIdType,
  data: { text: string | number }
) => (dispatch: dispatchType) => {
  return post(apiComments(leadId), data).then(res =>
    dispatch(addCommentSuccess(res.data))
  );
};

export const fetchHistory = (leadId: leadIdType, params: { page: number }) => (
  dispatch: dispatchType
) => {
  dispatch(fetchingHistory());
  return query(
    `${apiHistory(leadId)}${qs.stringify(params, { addQueryPrefix: true })}`
  ).then(res => dispatch(fetchedHistory(res.data)));
};

export const toggleArchive = (
  leadId: leadIdType,
  data: { archived: boolean },
  actionSuccess: dispatchType
) => (dispatch: dispatchType) => {
  return put(`${API_LEAD}/${leadId}/archive`, data).then(res =>
    dispatch(actionSuccess(res.data))
  );
};

export const fetchLeadProcessing = (params?: { currentLeadId?: number }) => (
  dispatch: dispatchType
) => {
  dispatch(fetchingLead());

  return query(
    `${apiProcessing}${qs.stringify(params, { addQueryPrefix: true })}`
  )
    .then(res => {
      return dispatch(
        fetchedLead({
          ...res.data,
          statusCode: res.status,
        })
      );
    })
    .catch(({ response }) => {
      dispatch(
        requestFailure({
          statusCode: response && response.data && response.data.code,
        })
      );
      return Promise.reject();
    });
};

export const linkedLeadAsDuplicate = ({
  leadId,
  mainLeadId,
}: {
  leadId: leadIdType,
  mainLeadId: leadIdType,
}) => (dispatch: dispatchType) => {
  return put(apiAsDuplicate(leadId), { mainLeadId }).then(res => {
    return dispatch(
      updateLeadSuccess({
        ...res.data,
        statusCode: res.status,
      })
    );
  });
};

export const updateLeadProcessing = (leadId: leadIdType, data: LeadModel) => (
  dispatch: dispatchType
) => {
  return put(`${API_LEAD}/${leadId}/processing`, data)
    .then(res => {
      return dispatch(
        updateLeadSuccess({
          ...res.data,
          statusCode: res.status,
        })
      );
    })
    .catch(error => {
      dispatch(requestFailure(error.response.data));
      return Promise.reject(error);
    });
};

export const updateLeadProcessingClient = (
  leadId: leadIdType,
  data: ClientModel
) => (dispatch: dispatchType) => {
  return put(`${API_LEAD}/${leadId}/processing/client`, data)
    .then(res => {
      return dispatch(
        updateLeadSuccess({
          ...res.data,
          statusCode: res.status,
        })
      );
    })
    .catch(error => {
      dispatch(requestFailure(error.response.data));
      return Promise.reject(error);
    });
};
