import { handleActions } from 'redux-actions';

import {
  fetchedUsers,
  updateUserSuccess,
  setFilters,
  fetchedReferrers,
} from '../actions/users';

const updateUser = (items, user) =>
  items.map(item => (item.id === user.id ? { ...user } : item));

export default handleActions(
  {
    [fetchedUsers](state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },

    [updateUserSuccess](state, action) {
      return {
        ...state,
        items: updateUser(state.items, action.payload),
      };
    },

    [setFilters](state, action) {
      return {
        ...state,
        filters: action.payload,
      };
    },

    [fetchedReferrers](state, action) {
      return {
        ...state,
        referrers: action.payload,
      };
    },
  },
  { pager: {}, filters: {}, items: [] }
);
