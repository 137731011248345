import React, { Component } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import { LocaleProvider } from 'antd';
import ru_RU from 'antd/lib/locale-provider/ru_RU';

import store from './store';
import routes from './router/routes';
import { getLink } from './router/helpers';

import BaseLayout from './containers/Layout/BaseLayout';
import RedirectToLogin from './containers/RedirectToLogin';
import ScrollToTop from './components/ScrollToTop';

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <LocaleProvider locale={ru_RU}>
          <BrowserRouter>
            <ScrollToTop>
              <Switch>
                <Route
                  exact
                  path={routes.login.path}
                  component={routes.login.component}
                />
                <Redirect exact from={'/'} to={getLink(routes.calculator)} />
                <Route path={'/'} component={BaseLayout} />
              </Switch>
              <RedirectToLogin />
            </ScrollToTop>
          </BrowserRouter>
        </LocaleProvider>
      </Provider>
    );
  }
}

export default App;
