import { handleActions } from 'redux-actions';

import { fetchedSources } from '../actions/sources';

export default handleActions(
  {
    [fetchedSources](state, action) {
      return [...action.payload];
    },
  },
  []
);
