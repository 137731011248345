// @flow
import { createAction } from 'redux-actions';

import { query } from '../helpers/axios';

type DispatchType = any;

const API_USERS = '/v1/users';

export const fetchSubordinatesSuccess = createAction(
  'FETCH_SUBORDINATE_SUCCESS'
);

export const fetchSubordinates = (
  userId: string | number,
  params?: { perPage: number }
) => (dispatch: DispatchType) => {
  return query(`${API_USERS}/${userId}/subordinates`).then(res =>
    dispatch(fetchSubordinatesSuccess(res.data))
  );
};
