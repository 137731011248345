import subscribe from './subscribe';

const pushManagerListener = store => {
  let unsubscribe;

  const memoListener = isAuth => {
    // try register and push to server only if user is login
    if (isAuth && !unsubscribe) {
      unsubscribe = subscribe();
    }

    if (!isAuth && unsubscribe) {
      unsubscribe();
      // clear
      unsubscribe = null;
    }
  };

  const listener = () => {
    const {
      login: { isAuth },
    } = store.getState();

    memoListener(isAuth);
  };

  store.subscribe(listener);
};

export default pushManagerListener;
