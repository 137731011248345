import { createAction } from 'redux-actions';

import { query } from '../helpers/axios';

const API_BANKS = '/v1/banks';

export const fetchedBanks = createAction('FETCHED_BANKS');

export const fetchBanks = () => dispatch => {
  return query(API_BANKS).then(res => {
    dispatch(fetchedBanks(res.data));
  });
};
