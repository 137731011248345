import { handleActions } from 'redux-actions';
import { fetchedOwnerList } from '../actions/owners';

export default handleActions(
  {
    [fetchedOwnerList](state, action) {
      return {
        ...action.payload,
      };
    },
  },
  {}
);
