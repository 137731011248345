import { handleActions } from 'redux-actions';

import { fetchedCompanies, setFilters } from '../actions/companies';

export default handleActions(
  {
    [fetchedCompanies](state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },

    [setFilters](state, action) {
      return {
        ...state,
        filters: action.payload,
      };
    },
  },
  { items: [], pager: {}, filters: {} }
);
