import { createAction } from 'redux-actions';
import qs from 'qs';

import { query } from '../helpers/axios';

const API_OWNER = '/v1/users/owners';

export const fetchedOwnerList = createAction('FETCHED_OWNER_LIST');

export const fetchOwnerList = (params: {
  companyId: string | number,
  active: boolean,
}) => dispatch => {
  return query(
    `${API_OWNER}${qs.stringify(params, { addQueryPrefix: true })}`
  ).then(res => dispatch(fetchedOwnerList(res.data)));
};
