import React from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';

const loginUrl = '/login';

class RedirectToLogin extends React.Component {
  render() {
    const { loginError, location: { pathname } } = this.props;

    return loginError && pathname !== loginUrl ? (
      <Redirect to="/login" />
    ) : null;
  }
}

export default withRouter(
  connect(state => ({
    loginError: state.login.loginError,
  }))(RedirectToLogin)
);
