import axios from 'axios';

export const HOST_API = process.env.REACT_APP_HOST_API || '';
export const API = HOST_API + '/api';

export const axiosInstance = axios.create({
  baseURL: API,
});

export const refreshAxiosInstance = axios.create({
  baseURL: API,
});

export function query(url, data) {
  return axiosInstance.get(url, data);
}

export function post(url, data, config) {
  return axiosInstance.post(url, data, config);
}

export function put(url, data) {
  return axiosInstance.put(url, data);
}

export function del(url) {
  return axiosInstance.delete(url);
}
