import { createAction } from 'redux-actions';

import { query, put, post } from '../helpers/axios';

const API_PROFILE = '/v1/user/profile';
const API_NOTIFICATIONS = '/v1/user/notifications';
const API_NOTIFICATIONS_CONFIG = '/v1/user/notifications/config';
const API_WIDGET = '/v1/user/widget';

export const fetchedProfile = createAction('FETCHED_PROFILE');
export const updateNotificationsSuccess = createAction(
  'UPDATE_NOTIFICATIONS_SUCCESS'
);
export const updateUserDataSuccess = createAction('UPDATE_USER_DATA_SUCCESS');
export const requestWidgetLinkSuccess = createAction(
  'REQUEST_REFINANCING_LINK_SUCCESS'
);
export const fetchedNotificationsConfig = createAction(
  'FETCHED_NOTIFICATIONS_CONFIG'
);
export const updateUserDataFailure = createAction('UPDATE_USER_DATA_FAILURE');

export const fetchProfile = () => dispatch => {
  return query(API_PROFILE).then(res => dispatch(fetchedProfile(res.data)));
};

export const fetchNotificationsConfig = () => dispatch => {
  return query(API_NOTIFICATIONS_CONFIG).then(res =>
    dispatch(fetchedNotificationsConfig(res.data))
  );
};

export const updateNotifications = data => dispatch => {
  return put(API_NOTIFICATIONS, data).then(res => {
    dispatch(updateNotificationsSuccess(res.data));
  });
};

export const updateUserData = data => dispatch => {
  return put(API_PROFILE, data)
    .then(res => dispatch(updateUserDataSuccess(res.data)))
    .catch(error => {
      dispatch(updateUserDataFailure(error.response.data));
      return Promise.reject();
    });
};

export const requestWidgetLink = widgetType => dispatch => {
  return post(API_WIDGET, { type: widgetType }).then(res =>
    dispatch(requestWidgetLinkSuccess(res.data))
  );
};
