import { createAction } from 'redux-actions';

import actionCreator from './actionCreator';
import { query } from '../helpers/axios';

const API_USERS = '/v1/users';

export const fetchedBusinessPartners = createAction(
  'FETCHED_BUSINESS_PARTNERS_SUCCESS'
);

export const fetchBusinessPartners = () =>
  actionCreator({
    fetch: () => query(`${API_USERS}/business-partners`),
    success: {
      action: res => fetchedBusinessPartners(res.data),
    },
  });
