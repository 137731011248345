import { createAction } from 'redux-actions';
import qs from 'qs';

import { query, post, HOST_API } from '../helpers/axios';
import { checkingArchive } from '../helpers/downloadArchive';

type DispatchType = ({}) => Promise<{}>;

const API_DEALS = '/v1/deals';
const API_FILES = '/v1/files';
const apiArchiveCheck = taskId => `${API_FILES}/archive/${taskId}`;

export const fetchedDeals = createAction('FETCHED_DEALS');
export const setFilters = createAction('SET_FILTERS');
export const updateStatusDealsSuccess = createAction(
  'UPDATE_STATUS_DEAL_SUCCESS'
);

export const fetchDeals = params => dispatch => {
  return query(
    `${API_DEALS}${qs.stringify(params, { addQueryPrefix: true })}`
  ).then(res => dispatch(fetchedDeals(res.data)));
};

export const toggleArchiveSuccess = createAction(
  'TOGGLE_DEALS_ARCHIVE_SUCCESS'
);

export const fetchExport = (data: {}, objWithStatus: { getStatus: string }) => (
  dispatch: DispatchType
) => {
  return post(
    `${API_DEALS}/export${qs.stringify(data, { addQueryPrefix: true })}`,
    {}
  ).then(res =>
    checkingArchive(
      () => query(apiArchiveCheck(res.data.id)),
      objWithStatus
    ).then(({ result: { downloadUrl } }) => `${HOST_API}${downloadUrl}`)
  );
};
