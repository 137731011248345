// @flow
import { createAction } from 'redux-actions';
import qs from 'qs';

import { query } from '../helpers/axios';

const API_CLIENTS = '/v1/clients';

export const fetchedClients = createAction('FETCHED_CLIENTS');
export const setFilters = createAction('SET_CLIENTS_FILTERS');

export const fetchClients = (params: {
  blocked?: boolean,
  search?: string,
  createdFrom?: string,
  createdTo?: string,
  page?: string,
}) => (dispatch: (args: { payload: any }) => any) => {
  return query(
    `${API_CLIENTS}${qs.stringify(params, { addQueryPrefix: true })}`
  ).then(res => dispatch(fetchedClients(res.data)));
};
