import { handleActions } from 'redux-actions';

import {
  fetchedCompany,
  fetchCompanyFailure,
  udateCompanySuccess,
  fetchingCompany,
  requestFailure,
} from '../actions/companyEdit';

const initialState = { name: '', description: '' };

export default handleActions(
  {
    [fetchingCompany](state, action) {
      return {
        // clear the previous state
        ...initialState,
        loading: true,
      };
    },

    [fetchedCompany](state, action) {
      return {
        ...action.payload,
      };
    },

    [fetchCompanyFailure](state, action) {
      return {
        ...action.payload,
      };
    },

    [udateCompanySuccess](state, action) {
      return {
        ...state,
        success: true,
      };
    },

    [requestFailure](state, action) {
      return {
        ...state,
        errors: action.payload.errors,
      };
    },
  },
  initialState
);
