import { handleActions } from 'redux-actions';

import {
  fetchedLeads,
  setFilters,
  updateStatusLeadsSuccess,
  toggleArchiveSuccess,
  fetchingSimilarLeads,
  fetchedSimilarLeadsSuccess,
  fetchedSimilarLeadsFailure,
} from '../actions/leads';

export default handleActions(
  {
    [fetchedLeads](state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },

    [fetchingSimilarLeads](state, action) {
      return {
        ...state,
        similarLeads: {
          ...state.similarLeads,
          loading: true,
        },
      };
    },

    [fetchedSimilarLeadsSuccess](state, action) {
      return {
        ...state,
        similarLeads: action.payload,
      };
    },

    [fetchedSimilarLeadsFailure](state, action) {
      return {
        ...state,
        similarLeads: {
          ...state.similarLeads,
          loading: false,
        },
      };
    },

    [setFilters](state, action) {
      return {
        ...state,
        filters: action.payload,
      };
    },

    [updateStatusLeadsSuccess](state, action) {
      return {
        ...state,
        items: state.items.map(
          item =>
            item.id === action.payload.data.id ? action.payload.data : item
        ),
      };
    },

    [toggleArchiveSuccess](state, action) {
      const nextStateItems = state.items.slice();
      const index = nextStateItems.findIndex(
        ({ id }) => id === action.payload.id
      );
      if (index > -1) {
        nextStateItems.splice(index, 1);
      }
      return {
        ...state,
        items: nextStateItems,
      };
    },
  },
  {
    filters: {},
    items: [],
    pager: {},
    similarLeads: { items: [], pager: {} },
    metaData: null,
  }
);
