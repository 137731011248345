import { handleActions } from 'redux-actions';
import {
  fetchedDeals,
  setFilters,
  updateStatusDealsSuccess,
  toggleArchiveSuccess,
} from '../actions/deals';

export default handleActions(
  {
    [fetchedDeals](state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },

    [setFilters](state, action) {
      return {
        ...state,
        filters: action.payload,
      };
    },

    [updateStatusDealsSuccess](state, action) {
      return {
        ...state,
        items: state.items.map(
          item => (item.id === action.payload.id ? action.payload : item)
        ),
      };
    },

    [toggleArchiveSuccess](state, action) {
      const nextStateItems = state.items.slice();
      const index = nextStateItems.findIndex(
        ({ id }) => id === action.payload.id
      );
      if (index > -1) {
        nextStateItems.splice(index, 1);
      }
      return {
        ...state,
        items: nextStateItems,
      };
    },
  },
  { items: [], pager: {}, filters: {} }
);
