import { handleActions } from 'redux-actions';

import {
  fetchedProfile,
  updateNotificationsSuccess,
  updateUserDataSuccess,
  requestWidgetLinkSuccess,
  fetchedNotificationsConfig,
  updateUserDataFailure,
} from '../actions/profile';

export default handleActions(
  {
    [fetchedProfile](state, action) {
      const { notificationSettings, widgetLinks, ...user } = action.payload;

      return {
        ...state,
        user,
        widgetLinks,
        notifications: notificationSettings,
      };
    },

    [fetchedNotificationsConfig](state, action) {
      return {
        ...state,
        notificationsConfig: action.payload,
      };
    },

    [updateNotificationsSuccess](state, action) {
      return {
        ...state,
        notifications: action.payload.notificationSettings,
      };
    },

    [updateUserDataSuccess](state, action) {
      const { notificationSettings, widgetLinks, ...user } = action.payload;

      return {
        ...state,
        user: user,
      };
    },

    [updateUserDataFailure](state, action) {
      return {
        ...state,
        errors: action.payload.errors,
      };
    },

    [requestWidgetLinkSuccess](state, action) {
      return {
        ...state,
        widgetLinks: { ...state.widgetLinks, ...action.payload.widgetLinks },
      };
    },
  },
  { user: { roles: [] } }
);
