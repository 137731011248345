import { createAction } from 'redux-actions';

import { query, put, post } from '../helpers/axios';

const API_COMPANIES = '/v1/companies';
const apiWithIdCompanies = companyId => `${API_COMPANIES}/${companyId}`;

export const fetchingCompany = createAction('FETCHING_COMPANY');
export const fetchedCompany = createAction('FETCHED_COMPANY');
export const fetchCompanyFailure = createAction('FETCH_COMPANY_FAILURE');
export const udateCompanySuccess = createAction('UPDATE_COMPANY_SUCCESS');
export const requestFailure = createAction('REQUEST_COMPANY_FAILURE');

export const fetchCompany = companyId => dispatch => {
  dispatch(fetchingCompany());
  return query(apiWithIdCompanies(companyId))
    .then(res => dispatch(fetchedCompany(res.data)))
    .catch(error => {
      dispatch(fetchCompanyFailure(error.response.data));
      return Promise.reject();
    });
};

export const udateCompany = (companyId, data) => dispatch => {
  return put(apiWithIdCompanies(companyId), data)
    .then(res => dispatch(udateCompanySuccess(res.data)))
    .catch(error => {
      dispatch(requestFailure(error.response.data));
      return Promise.reject();
    });
};

export const companyCreate = data => dispatch => {
  return post(API_COMPANIES, data).catch(error => {
    dispatch(requestFailure(error.response.data));
    return Promise.reject();
  });
};
