// @flow
import { createAction } from 'redux-actions';

import { query, put, del, post } from '../helpers/axios';

type DispatchType = ({}) => Promise<{}>;
export type ClientType = {
  phoneNumber: string,
  email: string,
  firstName: string,
  lastName: string,
  patronymic: string,
  deletable?: boolean,
  blocked?: boolean,
};

const API_CLIENTS = '/v1/clients';
const getApiClient = clientId => `${API_CLIENTS}/${clientId}`;

export const fetchingClient = createAction('FETCHING_CLIENT');
export const fetchedClient = createAction('FETCHED_CLIENT');
export const fetchedClientFailure = createAction('FETCHED_CLIENT_FAILURE');
export const clientUpdateSuccess = createAction('CLIENT_UPDATE_SUCCESS');
export const clientToggleDisableSuccess = createAction(
  'CLIENT_DISABLE_SUCCESS'
);

export const fetchClient = (clientId: string) => (dispatch: DispatchType) => {
  dispatch(fetchingClient());
  return query(getApiClient(clientId))
    .then(res => dispatch(fetchedClient(res.data)))
    .catch(error => {
      dispatch(fetchedClientFailure(error.response.data));
      return Promise.reject();
    });
};

export const clientUpdate = (clientId: string, data: ClientType) => (
  dispatch: DispatchType
) => {
  return put(getApiClient(clientId), data).then(res =>
    dispatch(clientUpdateSuccess(res.data))
  );
};

export const clientDelete = (clientId: string) => (dispatch: DispatchType) => {
  return del(getApiClient(clientId));
};

export const clientCreate = (data: ClientType) => (dispatch: DispatchType) => {
  return post(API_CLIENTS, data).then(res => ({ payload: res.data })); // action format
};

export const clientToggleDisable = (clientId: string, block: boolean) => (
  dispatch: DispatchType
) => {
  return put(`${getApiClient(clientId)}/block`, { block }).then(res =>
    dispatch(clientToggleDisableSuccess(res.data))
  );
};
