import { createAction } from 'redux-actions';

import { query } from '../helpers/axios';

const API_ENUMS = '/v1/enums';

export const fetchedEnums = createAction('FETCHED_ENUMS');

export const fetchEnums = () => dispatch => {
  return query(API_ENUMS).then(res => dispatch(fetchedEnums(res.data)));
};
