import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import reducers from '../reducers';

import axiosInterceptor from '../helpers/axiosInterceptor';
import pushManagerListener from '../helpers/pushNotifications/pushManagerListener';

const initialState = {};
const enhancers = [];
const middleware = [thunk];

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (devToolsExtension) {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
);

const store = createStore(reducers, initialState, composedEnhancers);

axiosInterceptor(store);
pushManagerListener(store);

export default store;
