// Styles maybe be override by module.less
import './styles/index.less';

import React from 'react';
import ReactDOM from 'react-dom';
import { notification } from 'antd';

import './configs';
import App from './App';
import * as serviceWorker from './serviceWorker';

// Styles should override ant's styles
import './styles/overrideStyles/index.less';

const openNotificationNewContent = () => {
  const args = {
    message: 'Доступна новая версия приложения, пожалуйста обновите страницу.',
    duration: 0,
  };
  notification.success(args);
};

ReactDOM.render(<App />, document.getElementById('root'));
serviceWorker.register({ onUpdate: openNotificationNewContent });
