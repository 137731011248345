// @flow
import { matchPath } from 'react-router-dom';
import memoize from 'lodash/memoize';

import routes from './routes';

const _getRouteParams = routes =>
  memoize(pathname => {
    for (let key in routes) {
      const route = routes[key];
      const match = matchPath(pathname, {
        path: route.path,
        exact: true,
      });

      if (match) {
        return { route, match };
      }
    }
  });

export const getRouteParams = (
  location: ?{ pathname?: string },
  routes: {}
) => {
  return location ? _getRouteParams(routes)(location.pathname) : null;
};

export const getLink = (
  route: { path: string },
  params: {} = {},
  location: ?{ pathname?: string }
) => {
  const { match, route: _route } = getRouteParams(location, routes) || {
    match: { params: {} },
    route: { path: '' },
  };
  route = route || _route;

  return route.path
    .split('/')
    .map(path => {
      if (path[0] === ':') {
        const paramName = path.slice(1);
        return params[paramName] || match.params[paramName];
      }

      return path;
    })
    .join('/');
};
