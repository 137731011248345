import { handleActions } from 'redux-actions';
import { fetchedOperators } from '../actions/operators';

export default handleActions(
  {
    [fetchedOperators](state, action) {
      return {
        ...action.payload,
      };
    },
  },
  { items: [], pager: {} }
);
