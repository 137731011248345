import React from 'react';
import { Layout, Menu, Icon } from 'antd';
import { Switch, Link, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter, matchPath } from 'react-router-dom';

import { fetchProfile } from '../../actions/profile';
import { fetchEnums } from '../../actions/enums';
import { fetchPermissions } from '../../actions/permissions';
import { saveNewTokens, logout } from '../../actions/login';
import { fetchSources } from '../../actions/sources';
import { fetchResourcePermissions } from '../../actions/resourcePermissions';
import { getLink } from '../../router/helpers';
import routes from '../../router/routes';

import Inspectlet from '../../components/Inspectlet';

import styles from './baseLayout.module.less';

const { Header, Content } = Layout;
class BaseLayout extends React.Component {
  static defaultProps = {
    layoutPermissions: {},
  };

  state = {
    mirroredLayoutPermissions: {},
    menuItems: [
      {
        permission: false,
        name: () => 'Калькулятор',
        key: 'calculator',
        url: routes.calculator.path,
      },
      {
        permission: false,
        name: () => 'Лиды',
        key: 'leads',
        url: routes.leads.path,
      },
      {
        permission: false,
        name: () => 'Заявки',
        key: 'deals',
        url: routes.deals.path,
      },
      {
        permission: false,
        name: () => 'Компании',
        key: 'companies',
        url: routes.companies.path,
      },
      {
        permission: false,
        name: () => 'Пользователи',
        key: 'users',
        url: routes.users.path,
      },
      {
        permission: false,
        name: () => 'Клиенты',
        key: 'clients',
        url: routes.clients.path,
      },
    ],
  };

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(fetchProfile());
    dispatch(fetchEnums());
    dispatch(fetchPermissions()); // FIXME: remove it from here
    dispatch(fetchSources()); // FIXME: remove it
    dispatch(fetchResourcePermissions(['layout']));
  }

  static getDerivedStateFromProps(props, state) {
    if (props.location.pathname !== state.mirroredLocationPathName) {
      const el = state.menuItems.find(item =>
        matchPath(props.location.pathname, {
          path: item.url,
          exact: item.exact,
        })
      );

      return {
        selectedKeys: [el && el.key],
        mirroredLocationPathName: props.location.pathname,
      };
    }

    if (props.layoutPermissions !== state.mirroredLayoutPermissions) {
      const { navigate = {} } = props.layoutPermissions;

      return {
        mirroredLayoutPermissions: props.layoutPermissions,
        menuItems: state.menuItems.map(item => ({
          ...item,
          permission: navigate[item.key],
        })),
      };
    }
    return null;
  }

  onClickLogout = () => {
    this.props.dispatch(logout());
    this.props.dispatch(saveNewTokens({}));
    this.props.history.push(getLink(routes.login));
  };

  render() {
    const {
      profile: { user = {} },
    } = this.props;
    const { menuItems, selectedKeys } = this.state;

    return (
      <React.Fragment>
        <Layout>
          {/* for logged users */}
          <Inspectlet user={user} />
          <Header>
            <div className={styles.headerContainer}>
              <div className={styles.headerLeft}>
                <Link to={getLink(routes.calculator)}>
                  <img
                    src="/static/logo.svg"
                    alt="logo"
                    className={styles.logoImg}
                  />
                </Link>

                <div className={styles.menuContainer}>
                  <Menu
                    mode="horizontal"
                    selectedKeys={selectedKeys}
                    className={styles.menu}
                  >
                    {menuItems.map(({ key, url, permission, name }) =>
                      permission ? (
                        <Menu.Item key={key}>
                          <Link to={url}>{name(user)}</Link>
                        </Menu.Item>
                      ) : null
                    )}
                  </Menu>
                </div>
              </div>

              {user.id && (
                <Menu mode="horizontal" className={styles.menu}>
                  <Menu.SubMenu
                    title={
                      <span className="submenu-title-wrapper">
                        {user.lastName}
                        &nbsp;
                        {user.firstName}
                        <Icon
                          type="down"
                          theme="outlined"
                          className={styles.subMenuArrowIcon}
                        />
                      </span>
                    }
                  >
                    <Menu.Item key="setting:1">
                      <Link to={routes.profile.path}>Профиль</Link>
                    </Menu.Item>
                    {/* will be added at future */}
                    {/* <Menu.Item key="setting:2"><Link to={routes.profile}>Настройки</Link></Menu.Item> */}
                    <Menu.Item key="setting:3" onClick={this.onClickLogout}>
                      Выход
                    </Menu.Item>
                  </Menu.SubMenu>
                </Menu>
              )}
            </div>
          </Header>
          <Content className={styles.content}>
            <Switch>
              <Route
                path={routes.calculator.path}
                component={routes.calculator.component}
              />
              <Route
                path={routes.leads.path}
                component={routes.leads.component}
              />
              <Route
                path={routes.deals.path}
                component={routes.deals.component}
              />
              <Route
                path={routes.companies.path}
                component={routes.companies.component}
              />
              <Route
                path={routes.users.path}
                component={routes.users.component}
              />
              <Route
                path={routes.clients.path}
                component={routes.clients.component}
              />
              <Route
                path={routes.profile.path}
                component={routes.profile.component}
              />
              <Route
                path={routes.serviceRefresh.path}
                component={routes.serviceRefresh.component}
              />
              <Route
                path={routes.notFound.path}
                component={routes.notFound.component}
              />
            </Switch>
          </Content>
        </Layout>
      </React.Fragment>
    );
  }
}

export default withRouter(
  connect(state => ({
    profile: state.profile,
    layoutPermissions: state.resourcePermissions.layout,
  }))(BaseLayout)
);
