import { handleActions } from 'redux-actions';

import {
  fetchedBankResultsSuccess,
  fetchBankResultsFailure,
  clearCalculator,
} from '../actions/calculator';
import { parseErrors } from '../helpers/getFormat';

const customErrorParseCalc = errors => {
  const { productType, ...err } = errors;

  return {
    ...err,
    creditTarget: productType,
  };
};

const initialState = {};

export default handleActions(
  {
    [fetchedBankResultsSuccess](state, action) {
      return {
        ...state,
        bankResults: action.payload.result,
      };
    },
    [fetchBankResultsFailure](state, action) {
      return {
        ...state,
        errors: customErrorParseCalc(
          parseErrors(action.payload && action.payload.errors)
        ),
      };
    },

    [clearCalculator](state, action) {
      return initialState;
    },
  },
  initialState
);
