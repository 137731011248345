import { createAction } from 'redux-actions';
import qs from 'qs';

import { query, post, put } from '../helpers/axios';

const API_DEALS = '/v1/deals';
const apiComments = dealId => `${API_DEALS}/${dealId}/comments`;
const apiAllComments = dealId => `${apiComments(dealId)}/all`;
const apiHistory = leadId => `${API_DEALS}/${leadId}/history`;

export const fetchingDeal = createAction('FETCHING_DEAL');
export const fetchDealFailure = createAction('FETCH_DEAL_FAILURE');
export const fetchedDeal = createAction('FETCHED_DEAL');

export const fetchingAllComments = createAction('FETCHING_ALL_COMMENTS');
export const fecthedAllComments = createAction('FETCHED_ALL_COMMENTS');
export const addCommentSuccess = createAction('ADD_COMMENT_DEAL_SUCCESS');
export const updateStatusDealSuccess = createAction(
  'UPDATE_STATUS_DEAL_SUCCESS'
);
export const updateDealSuccess = createAction('UPDATE_DEAL_SUCCESS');
export const updateDealFailure = createAction('UPDATE_DEAL_FAILURE');
export const fetchingHistory = createAction('FETCHING_DEAL_HISTORY');
export const fetchedHistory = createAction('FETCHED_DEAL_HISTORY');

export const fetchDeal = dealId => dispatch => {
  dispatch(fetchingDeal());
  return query(`${API_DEALS}/${dealId}`)
    .then(res => dispatch(fetchedDeal(res.data)))
    .catch(({ response }) => dispatch(fetchDealFailure(response.data)));
};

export const createDeal = data => dispatch => {
  return post(API_DEALS, data)
    .then(res => dispatch(updateDealSuccess(res.data)))
    .catch(error => {
      dispatch(fetchDealFailure(error.response.data));
      return Promise.reject();
    });
};

export const updateDeal = (dealId, data) => dispatch => {
  return put(`${API_DEALS}/${dealId}`, data)
    .then(res => {
      dispatch(updateDealSuccess(res.data));
    })
    .catch(error => {
      dispatch(updateDealFailure(error.response.data));
      return Promise.reject();
    });
};

export const fetchAllComments = (dealId, params) => dispatch => {
  dispatch(fetchingAllComments());

  return query(
    `${apiAllComments(dealId)}${qs.stringify(params, { addQueryPrefix: true })}`
  ).then(res => dispatch(fecthedAllComments(res.data)));
};

export const addComment = (dealId, data) => dispatch => {
  return post(apiComments(dealId), data).then(
    res => dispatch(addCommentSuccess({ entityId: dealId, data: res.data })) // FIXME: need other response
  );
};

export const updateStatusDeal = (dealId, data, actionSuccess) => dispatch => {
  return put(`${API_DEALS}/${dealId}/status`, data).then(res =>
    dispatch(actionSuccess(res.data))
  );
};

export const fetchHistory = (dealId, params) => dispatch => {
  dispatch(fetchingHistory());
  return query(
    `${apiHistory(dealId)}${qs.stringify(params, { addQueryPrefix: true })}`
  ).then(res => dispatch(fetchedHistory(res.data)));
};

export const toggleArchive = (dealId, data, actionSuccess) => dispatch => {
  return put(`${API_DEALS}/${dealId}/archive`, data).then(res =>
    dispatch(actionSuccess(res.data))
  );
};

export const setDataForCreateDeal = createAction('SET_DATA_FOR_CREATE_DEAL');
