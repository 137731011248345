// @flow
type ArgTypes = {
  fetch: () => Promise<any>,
  success?: { action?: (args: any) => any },
  failure?: { action?: (args: any) => any },
};
type DispatchType = (args: any) => any;

const actionCreator = ({ fetch, success = {}, failure = {} }: ArgTypes) => (
  dispatch: DispatchType
) =>
  fetch()
    .then(res => (success.action ? dispatch(success.action(res)) : res))
    .catch(error => {
      failure.action &&
        dispatch(failure.action(error.response && error.response.data));
      return Promise.reject();
    });

export default actionCreator;
