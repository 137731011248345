import { handleActions } from 'redux-actions';

import { fetchedResourcePermissions } from '../actions/resourcePermissions';

const initialState = {
  leads: { processing: {}, showFilters: {}, table: {} },
  profile: { subordinates: { view: false }, widgets: { view: false } },
  deals: { showFilters: {} },
};
export default handleActions(
  {
    [fetchedResourcePermissions](state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
  initialState
);
