import { handleActions } from 'redux-actions';

import { fetchSubordinatesSuccess } from '../actions/subordinates';

export default handleActions(
  {
    [fetchSubordinatesSuccess](state, action) {
      return action.payload;
    },
  },
  { items: [], pager: {} }
);
