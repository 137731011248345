import { createAction } from 'redux-actions';
import { query } from '../helpers/axios';
import qs from 'qs';

const API_OPERATORS = '/v1/users/operators';

export const fetchedOperators = createAction('FETCHED_OPERATORS');

export const fetchOperators = params => dispatch => {
  return query(
    `${API_OPERATORS}${qs.stringify(params, { addQueryPrefix: true })}`
  ).then(res => dispatch(fetchedOperators(res.data)));
};
