// @flow

import { post } from '../helpers/axios';

const API_PUSH = '/v1/push/subscribe';

export const saveSubscription = (params: {
  keys: {
    auth: string,
    p256dh: string,
  },
  endpoint: string,
}) => {
  return post(API_PUSH, params);
};
