import { createAction } from 'redux-actions';
import qs from 'qs';

import { query, post } from '../helpers/axios';

const API_USERS = '/v1/users';

export const fetchedUsers = createAction('FETCHED_USERS');
export const updateUserSuccess = createAction('UPDATE_USER_SUCCESS');
export const setFilters = createAction('SET_FILTERS');

export const fetchUsers = (params = {}) => dispatch => {
  return query(
    `${API_USERS}${qs.stringify(params, { addQueryPrefix: true })}`
  ).then(res => {
    dispatch(fetchedUsers(res.data));
  });
};

export const switchActive = userId => dispatch => {
  return post(
    `${API_USERS}/${userId}/switch-active
  `
  ).then(res => dispatch(updateUserSuccess(res.data)));
};

export const switchAutoAssignment = userId => dispatch => {
  return post(`${API_USERS}/${userId}/switch-auto-assignment`).then(res =>
    dispatch(updateUserSuccess(res.data))
  );
};

export const fetchedReferrers = createAction('USERS_REFERRERS_FETCH');
export const fetchReferrers = () => dispatch =>
  query(`${API_USERS}/referrers`).then(res =>
    dispatch(fetchedReferrers(res.data))
  );
