import { handleActions } from 'redux-actions';

import { fetchedBusinessPartners } from '../actions/businessPartners';

const initialState = { items: [], pager: {} };
export default handleActions(
  {
    [fetchedBusinessPartners](state, action) {
      return { ...state, ...action.payload };
    },
  },
  initialState
);
