import { combineReducers } from 'redux';

import login from './login';
import users from './users';
import companies from './companies';
import companyEdit from './companyEdit';
import enums from './enums';
import userEdit from './userEdit';
import profile from './profile';
import leads from './leads';
import leadEdit from './leadEdit';
import sources from './sources';
import permissions from './permissions';
import operators from './operators';
import deals from './deals';
import dealEdit from './dealEdit';
import owners from './owners';
import banks from './banks';
import clients from './clients';
import clientEdit from './clientEdit';
import resourcePermissions from './resourcePermissions';
import calculator from './calculator';
import subordinates from './subordinates';
import businessPartners from './businessPartners';

const appReducer = combineReducers({
  login,
  users,
  companies,
  enums,
  userEdit,
  companyEdit,
  profile,
  leads,
  leadEdit,
  sources,
  permissions,
  operators,
  deals,
  dealEdit,
  owners,
  banks,
  clients,
  clientEdit,
  resourcePermissions,
  calculator,
  subordinates,
  businessPartners,
});
const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
