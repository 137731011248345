import { handleActions } from 'redux-actions';

import {
  fetchedUser,
  requestFailure,
  fetchingUser,
  fetchChiefsSuccess,
} from '../actions/userEdit';

const initialState = {
  chiefs: { items: [] },
  curators: { items: [] },
  user: {
    data: {},
    metaData: {},
  },
};

export default handleActions(
  {
    [fetchingUser](state, action) {
      return {
        // clear the previous state
        ...initialState,
      };
    },

    [fetchedUser](state, action) {
      return {
        ...state,
        user: action.payload,
      };
    },

    [requestFailure](state, action) {
      return {
        ...state,
        ...action.payload,
        // TODO: remove it
        errors: (action.payload && action.payload.errors) || {}, // to avoid null from server
      };
    },

    [fetchChiefsSuccess](state, action) {
      return {
        ...state,
        chiefs: action.payload,
      };
    },
  },
  initialState
);
