import { handleActions } from 'redux-actions';
import {
  fetchedPermissions,
  fetchedPermissionsFailure,
} from '../actions/permissions';

export default handleActions(
  {
    [fetchedPermissions](state, action) {
      return {
        ...action.payload,
      };
    },

    [fetchedPermissionsFailure](state, action) {
      return {
        loading: false,
      };
    },
  },
  { loading: true }
);
