import { createAction } from 'redux-actions';
import qs from 'qs';

import { query } from '../helpers/axios';

const API_COMPANIES = '/v1/companies';

export const fetchedCompanies = createAction('FETCHED_COMPANIES');
export const setFilters = createAction('SET_FILTERS');

export const fetchCompanies = params => dispatch => {
  return query(
    `${API_COMPANIES}${qs.stringify(params, { addQueryPrefix: true })}`
  ).then(res => dispatch(fetchedCompanies(res.data)));
};
