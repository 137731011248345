import React, { Component } from 'react';

export default function asyncComponent({ loader, loading = () => null }) {
  class AsyncComponent extends Component {
    state = {
      component: null,
      load: true,
    };

    async componentDidMount() {
      const { default: component } = await loader();

      this.setState({
        component: component,
        load: false,
      });
    }

    render() {
      const C = this.state.component;
      const { load } = this.state;

      return load ? loading() : C ? <C {...this.props} /> : null;
    }
  }

  return AsyncComponent;
}
