// @flow
import { createAction } from 'redux-actions';

// import { query } from '../helpers/axios';
import actionCreator from './actionCreator';
import { post } from '../helpers/axios';

const API_CALCULATOR = '/v1/calculator';

export type CalculatorModel = {
  coBorrowers?: Array<{
    age: 0,
    financialParticipant: boolean,
  }>,
  docsType?: string,
  estateType?: string,
  initialPayment?: number,
  currency?: string,
  creditPeriod?: number,
  objectPrice?: number,
  productType?: string,
  age?: number,
  hasMotherCash?: boolean,
  financialParticipant?: boolean,
  motherCashPayment?: number,
  isNotResidentRF?: boolean,
  citizenship?: string,
  business?: {
    isExist: boolean,
    type: string,
    percent: number,
    exp: number, // in month
  },
  address?: string,
  currentWorkExp?: number,
  totalWorkExp?: number,
  relationship?: {
    married: boolean,
    marriageContract?: boolean,
    financialParticipant?: boolean,
    isNotResidentRF?: boolean,
    age?: 0,
  },
};

export const fetchedCalculator = createAction('FETCHED_CALCULATOR');
export const fetchCalculatorFailure = createAction('FETCH_CALCULATOR_FAILURE');
export const fetchedBankResultsSuccess = createAction(
  'FETCHED_BANK_RESULTS_SUCCESS'
);
export const fetchBankResultsFailure = createAction(
  'FETCH_BANK_RESULTS_FAILURE'
);
export const clearCalculator = createAction('CLEAR_CALCULATOR');

export const fetchBankResults = (data: CalculatorModel) =>
  actionCreator({
    fetch: () => post(`${API_CALCULATOR}/calculate`, data),
    success: {
      action: res => fetchedBankResultsSuccess(res.data),
    },
    failure: {
      action: fetchBankResultsFailure,
    },
  });
