// @flow
import { createAction } from 'redux-actions';
import qs from 'qs';

import { query, put, post } from '../helpers/axios';

const API_USERS = '/v1/users';

export const fetchingUser = createAction('FETCHING_USER');
export const fetchedUser = createAction('FETCHED_USER');
export const requestFailure = createAction('REQUEST_USER_EDIT_FAILURE');
export const fetchChiefsSuccess = createAction('FETCH_CHIEF_SUCCESS');

type DispatchType = any;
type UserType = {};

export const fetchUser = (userId: number | string) => (
  dispatch: DispatchType
) => {
  dispatch(fetchingUser());
  return query(`${API_USERS}/${userId}`)
    .then(res => dispatch(fetchedUser(res.data)))
    .catch(error => {
      dispatch(requestFailure(error.response && error.response.data));
      return Promise.reject();
    });
};

export const updateUser = (userId: string, data: UserType) => (
  dispatch: DispatchType
) => {
  return put(`${API_USERS}/${userId}`, data).catch(error => {
    dispatch(requestFailure(error.response.data));
    return Promise.reject(error);
  });
};

export const createUser = (data: UserType) => (dispatch: DispatchType) => {
  return post(API_USERS, data).catch(error => {
    dispatch(requestFailure(error.response.data));
    return Promise.reject(error);
  });
};

export const fetchChiefs = (params?: { companyId: number | string }) => (
  dispatch: DispatchType
) => {
  return query(
    `${API_USERS}/chiefs${qs.stringify(params, { addQueryPrefix: true })}`
  ).then(res => dispatch(fetchChiefsSuccess(res.data)));
};
