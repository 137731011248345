import { handleActions, combineActions } from 'redux-actions';

import {
  fetchingClient,
  fetchedClient,
  clientUpdateSuccess,
  clientToggleDisableSuccess,
  fetchedClientFailure,
} from '../actions/clientEdit';

export default handleActions(
  {
    [fetchingClient](state, action) {
      return {};
    },

    [combineActions(
      fetchedClient,
      fetchedClientFailure,
      clientUpdateSuccess,
      clientToggleDisableSuccess
    )](state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
  {}
);
