import { handleActions } from 'redux-actions';

import { loadState, saveState } from '../helpers/localStorage';
import { HOST_API } from '../helpers/axios';

import {
  loginSuccess,
  loginFailure,
  saveNewTokens,
  getRefreshAccessTokenFailure,
  saveIsAuth,
} from '../actions/login';

const domainCookie = HOST_API.split('.')
  .slice(-2)
  .join('.');
const user = loadState('user');

const saveNewTokensToLocaleStore = newTokens => {
  saveState('user', newTokens);

  const TOKEN_MAX_AGE = 60 * 60 * 24 * 7;
  document.cookie =
    `token=${newTokens.token}; path=/; domain=${domainCookie}` +
    `; Max-Age=${TOKEN_MAX_AGE}`;
  document.cookie =
    `refresh_token=${newTokens.refresh_token}; path=/` +
    `; domain=${domainCookie}; Max-Age=${TOKEN_MAX_AGE}`;
};

export default handleActions(
  {
    [loginSuccess](state, action) {
      saveNewTokensToLocaleStore(action.payload);
      return {
        ...state,
        user: action.payload,
        loginError: null,
      };
    },

    [loginFailure](state, action) {
      return {
        ...state,
      };
    },

    [saveNewTokens](state, action) {
      saveNewTokensToLocaleStore(action.payload);
      return {
        ...state,
        user: action.payload,
      };
    },

    [getRefreshAccessTokenFailure](state, action) {
      saveNewTokensToLocaleStore({});
      return {
        user: {},
        loginError: action.payload,
      };
    },

    [saveIsAuth](state, action) {
      if (!state.isAuth) {
        return { ...state, isAuth: action.payload };
      }

      return state;
    },
  },
  {
    user: user || {},
    loginError: user ? null : { code: 401 }, // If user is null, loginError set to error
    isAuth: false,
  }
);
