import { handleActions } from 'redux-actions';

import { fetchedEnums } from '../actions/enums';

const defaultEnum = { labels: {}, values: [] };
export default handleActions(
  {
    [fetchedEnums](state, action) {
      return {
        ...action.payload,
      };
    },
  },
  {
    rolesEnum: defaultEnum,
    productTypesEnum: defaultEnum,
    estateTypeEnum: defaultEnum,
  }
);
