// @flow
import React from 'react';
import { Helmet } from 'react-helmet';

const wid = process.env.REACT_APP_INSPECTLET_WID;
const nodeEnv = process.env.NODE_ENV;

type PropsType = {
  user: {
    email: string,
  },
};
export default class Inspectlet extends React.PureComponent<PropsType, {}> {
  render() {
    const { user } = this.props;

    return user.email && wid && nodeEnv === 'production' ? (
      <Helmet>
        {/* <!-- Begin Inspectlet Asynchronous Code --> */}
        <script type="text/javascript">
          {`
       (function () {
         window.__insp = window.__insp || [];
         __insp.push(['wid', ${wid}]);
         __insp.push(['identify', "${user.email}"]);
         var ldinsp = function () {
           if (typeof window.__inspld != "undefined") return; window.__inspld = 1; var insp = document.createElement('script'); insp.type = 'text/javascript'; insp.async = true; insp.id = "inspsync"; insp.src = ('https:' == document.location.protocol ? 'https' : 'http') + '://cdn.inspectlet.com/inspectlet.js?wid=${wid}&r=' + Math.floor(new Date().getTime() / 3600000); var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(insp, x);
         };
         setTimeout(ldinsp, 0);
       })();
       `}
        </script>
        {/* <!-- End Inspectlet Asynchronous Code --> */}
      </Helmet>
    ) : null;
  }
}
