import NotFound from '../containers/NotFound';
import {
  Login,
  Leads,
  LeadsImport,
  LeadCianImport,
  LeadCreate,
  Deals,
  LeadEdit,
  LeadsProcessing,
  DealEdit,
  DealCreate,
  Companies,
  CompanyEdit,
  Users,
  UserEdit,
  Clients,
  ClientEdit,
  Profile,
  ServiceRefresh,
  Calculator,
} from './AsyncImportContainers';

const relativeRoutes = {
  // login
  login: { path: '/login', component: Login },

  // leads
  leads: { path: '/leads', component: Leads },
  leadsImport: { parent: 'leads', path: '/import', component: LeadsImport },
  leadCianImport: {
    parent: 'leads',
    path: '/cian-import',
    component: LeadCianImport,
  },
  leadEdit: { parent: 'leads', path: '/edit/:leadId', component: LeadEdit },
  leadCreate: { parent: 'leads', path: '/create', component: LeadCreate },
  leadsProcessing: {
    parent: 'leads',
    path: '/processing',
    component: LeadsProcessing,
  },

  // deals
  deals: { path: '/deals', component: Deals },
  dealEdit: { parent: 'deals', path: '/edit/:dealId', component: DealEdit },
  dealCreate: { parent: 'deals', path: '/create', component: DealCreate },

  // companies
  companies: { path: '/companies', component: Companies },
  companyEdit: {
    parent: 'companies',
    path: '/edit/:companyId',
    component: CompanyEdit,
  },
  companyCreate: {
    parent: 'companies',
    path: '/create',
    component: CompanyEdit,
  },

  // users
  users: { path: '/users', component: Users },
  userEdit: { parent: 'users', path: '/edit/:userId', component: UserEdit },
  userCreate: { parent: 'users', path: '/create', component: UserEdit },

  // clients
  clients: { path: '/clients', component: Clients },
  clientEdit: {
    parent: 'clients',
    path: '/edit/:clientId',
    component: ClientEdit,
  },
  clientCreate: {
    parent: 'clients',
    path: '/create',
    component: ClientEdit,
  },

  // profile
  profile: { path: '/profile', component: Profile },

  // serviceRefresh
  serviceRefresh: { path: '/serviceRefresh', component: ServiceRefresh },

  // Calculator
  calculator: { path: '/calculator', component: Calculator },

  // Page not found
  notFound: { path: '*', component: NotFound },
};

const getRoute = routeName => {
  const route = relativeRoutes[routeName];
  const parentRoute = route.parent ? getRoute(route.parent) : {};

  return {
    component: route.component,
    path: (parentRoute.path || '') + route.path,
  };
};

const absoluteRoutes = {};
Object.keys(relativeRoutes).forEach(routeName => {
  absoluteRoutes[routeName] = getRoute(routeName);
});
export default absoluteRoutes;
