import debug from 'debug';

import { axiosInstance } from './axios';

import { getRefreshAccessToken, saveIsAuth } from '../actions/login';

const log = debug('App:axiosInterceptor');

export default store => {
  log('Initialize header axios', store.getState().login.user);
  axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${
    store.getState().login.user.token
  }`;

  // prevent multiple access token requests
  let accessTokenIsRefreshing;
  const resetAccessTokenIsRefreshing = res => {
    accessTokenIsRefreshing = null;
  };

  // refresh access token
  const getNewAccessToken = () => {
    if (!accessTokenIsRefreshing) {
      const { refresh_token } = store.getState().login.user;

      accessTokenIsRefreshing = store.dispatch(
        getRefreshAccessToken(refresh_token)
      );

      accessTokenIsRefreshing.then(
        resetAccessTokenIsRefreshing,
        resetAccessTokenIsRefreshing
      );
    }

    return accessTokenIsRefreshing;
  };

  // Update tokens in headers
  let { user } = store.getState().login; // initial value
  const updateAxiosHeaders = () => {
    const nextUser = store.getState().login.user;
    if (nextUser !== user) {
      user = nextUser;
      log('updateAxiosHeaders', user);

      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${
        user.token
      }`;
    }
  };

  // subscribe for update axios instance
  store.subscribe(updateAxiosHeaders);

  // intercept response
  axiosInstance.interceptors.response.use(
    response => {
      // save isAuth in the store
      const {
        login: { isAuth },
      } = store.getState();

      !isAuth && store.dispatch(saveIsAuth(true));

      return response;
    },
    error => {
      const originalRequest = error.config;

      if (
        error.response &&
        error.response.status === 401 &&
        !originalRequest._isRetryRequest
      ) {
        originalRequest._isRetryRequest = true;
        return getNewAccessToken()
          .then(res => {
            originalRequest.headers['Authorization'] = `Bearer ${
              res.data.token
            }`;

            log('then getNewAccessToken', res);
            return axiosInstance(originalRequest);
          })
          .catch(err => {
            log('catch getNewAccessToken', err);

            return Promise.reject(err);
          });
      } else {
        return Promise.reject(error);
      }
    }
  );
};
