import { createAction } from 'redux-actions';
import { query } from '../helpers/axios';

export const API_PERMISSIONS = '/v1/user/permissions';

export const fetchedPermissions = createAction('FETCHED_PERMISSIONS');
export const fetchedPermissionsFailure = createAction(
  'FETCHED_PERMISSIONS_FAILURE'
);

export const fetchPermissions = () => dispatch => {
  return query(API_PERMISSIONS)
    .then(res => dispatch(fetchedPermissions(res.data)))
    .catch(() => dispatch(fetchedPermissionsFailure()));
};
