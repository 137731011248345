import React from 'react';
import setLodash from 'lodash/set';
import moment from 'moment';

export const parsePhoneNumber = phoneNumber => {
  let newString = '+7 ';
  const emptyTemplate = ' ';

  const strNumber = phoneNumber ? String(phoneNumber) : '';

  newString += (strNumber.slice(0, 3) || emptyTemplate.repeat(3)) + ' ';
  newString += (strNumber.slice(3, 6) || emptyTemplate.repeat(3)) + '-';
  newString += (strNumber.slice(6, 8) || emptyTemplate.repeat(2)) + '-';
  newString += strNumber.slice(8, 10);

  return newString;
};

export const parseValuesFromLocation = obj => {
  const newObj = {};

  Object.keys(obj).forEach(key => {
    if (obj[key] && typeof obj[key] !== 'object') {
      const newValue = Number(obj[key]);
      // check on NaN
      if (!Object.is(newValue, NaN)) {
        newObj[key] = newValue;
      } else {
        // maybe it is string
        newObj[key] = obj[key];
      }
    } else {
      newObj[key] = obj[key];
    }
  });

  return newObj;
};

export const parseUserInput = (value = '') =>
  value.replace(/\n|\r|<\//g, '<br>');

export const pluralize = (n, titles) => {
  return titles[
    n % 10 === 1 && n % 100 !== 11
      ? 0
      : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)
        ? 1
        : 2
  ];
};

export const parseErrors = errors => {
  const errorsKey = Object.keys(errors || {});
  const nextErrors = {};

  errorsKey.forEach(errorKey =>
    setLodash(nextErrors, errorKey, errors[errorKey].join(' '))
  );

  return nextErrors;
};

export const getMomentDate = date =>
  date === null ? null : moment.isMoment(date) ? date : moment(date);

export const daysDiffBetweenToday = momentDate =>
  moment.isMoment(momentDate)
    ? momentDate
        .clone()
        .startOf('day')
        .diff(moment().startOf('day'), 'days')
    : null;

export const parseDateTimeToStr = ({ date, style }) => {
  const momentDate = getMomentDate(date);
  const isDateExpired = momentDate - moment() < 0;

  switch (daysDiffBetweenToday(momentDate)) {
    case 0:
      return (
        <span style={isDateExpired ? style : null}>
          Перезвонить сегодня в {momentDate.format('HH:mm')}
        </span>
      );
    case 1:
      return `Перезвонить завтра в ${momentDate.format('HH:mm')}`;
    default:
      return (
        <span style={isDateExpired ? style : null}>
          Перезвонить {momentDate.format('DD MMMM [в] HH:mm')}
        </span>
      );
  }
};
